import React from "react";
import { graphql } from "gatsby";
import HeadingWithSub from "../components/HeadingWithSub";
import LinkToTour from "../components/LinkToTour";

import { replacedTermName } from "../utilities/helpers";
import ParentRouteHandler from "../components/ParentRouteHandler";

const AudioTours = ({ data, location }) => {
  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");

  return (
    <>
      {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}

      <div className="tours">
        <HeadingWithSub main={"Audio Tours"} h2={true} />
        {data.allTaxonomyTermTours.edges.map(({ node: tour_term }, index) => {
          return (
            <LinkToTour
              key={index}
              title={tour_term.field_mobile_display_name}
              subtitle={replacedTermName(tour_term.name)}
              link={tour_term.path.alias}
              image={
                tour_term.rel.image[0].localFile
                  ? tour_term.rel.image[0].localFile
                  : null
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default AudioTours;

export const query = graphql`
  query {
    collectionImage: file(
      relativePath: { eq: "the-collection-blue-dog-home.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 82)
      }
    }
    allTaxonomyTermTours(
      sort: { fields: [field_mobile_app_menu_weight], order: DESC }
    ) {
      edges {
        node {
          name
          drupal_id
          field_mobile_app_menu_weight
          field_mobile_display_name
          path {
            alias
          }
          rel: relationships {
            image: field_lightbox_image {
              localFile {
                relativePath
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
