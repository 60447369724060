import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import arrow from "../images/arrow.svg";

const DirectionContainer = styled.div`
  transform:
    ${(props) => {
      let deg;
      if (props.direction === `left`) {
        deg = 180;
      } else if (props.direction === `right`) {
        deg = 0;
      } else if (props.direction === `up`) {
        deg = 90;
      } else {
        deg = -90;
      }
      return `rotate(${deg})`;
    }}
  );

`;

const Arrow = ({ direction }) => (
  <DirectionContainer direction={direction}>
    <img src={arrow} alt="back arrow" />
  </DirectionContainer>
);

Arrow.propTypes = {
  direction: PropTypes.string,
};

Arrow.defaultProps = {
  direction: `right`,
};

export default Arrow;
