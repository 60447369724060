import React from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";
import { graphql, StaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Arrow from "./Arrow";

const PlaceHolder = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "artwork-placeholder.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 82)
          }
        }
      }
    `}
    render={(data) => {
      return <GatsbyImage image={getImage(data.placeholderImage)} />;
    }}
  />
);

const ATagType = ({
  isDownload,
  link,
  children,
  newTab,
  isPdf,
  originPath,
  origin,
  title,
  category,
}) =>
  isDownload ? (
    <a href={link} target={newTab ? "_blank" : ""} rel="noreferrer">
      {children}
    </a>
  ) : isPdf ? (
    <Link
      to="/reader"
      state={{
        pdf: link,
        originalPath: originPath,
        origin: origin,
        title: title,
        category: category,
      }}
    >
      {children}
    </Link>
  ) : (
    <Link to={link}>{children}</Link>
  );

const Container = styled.div`
  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-bottom: solid 1px ${colors.gray3};
  }
`;

const Left = styled.div`
  margin-right: 10px;
  max-width: 82px;

  .img {
    max-width: 82px;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;

  .title {
    font-size: 16px;
    color: ${colors.text};
    margin-bottom: 4px;
  }

  .subtitle {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
  }
  .arrow {
    min-width: 9px;
    margin-left: 10px;
  }
`;

const LinkToTour = ({
  title,
  subtitle,
  image = null,
  link = "/",
  displayImage = true,
  isDownload = false,
  newTab = false,
  isPdf = false,
  originPath = "",
  origin = "",
  category = "",
}) => {
  if (!image || image === null) {
    image = <PlaceHolder />;
  } else {
    image = <GatsbyImage image={getImage(image)} width={82} />;
  }

  return (
    <Container>
      <ATagType
        isDownload={isDownload}
        link={subtitle !== "Coming Soon" ? link : ""}
        newTab={newTab}
        isPdf={isPdf}
        originPath={originPath}
        origin={origin}
        title={title}
        category={category}
      >
        {displayImage && image && (
          <Left>{ image }</Left>
        )}
        <Right>
          <div>
            <div className={"title"}>{title}</div>
            <div className={"subtitle"}>{subtitle}</div>
          </div>
          <div className="arrow">
            <Arrow direction={"right"} />
          </div>
        </Right>
      </ATagType>
    </Container>
  );
};

export default LinkToTour;
